import React, { useState, useEffect } from "react";
import { ContainerAuto } from "@/shared/ContainerAuto";
import { Menu, ContentLeft, Logo, SectionScroll } from "../../styles";
import ContentIcon from "@/shared/ContentIcon";
import LoginSubscription from "@/shared/Header/components/LoginSubscription";
import { handleClick } from "@/shared/Analitics/GoogleAnalytics4";
import MenuLateral from "../Menu/components/MenuLateral";

const HeadingDefault = ({
  isHome,
  suscriptionPrice,
  isPreview = false,
  menulateral,
  sectionData,
  subheaderEnable,
  showGoolgeOneTap,
  urlSubscription = "https://365.clarin.com/?utm_source=clarin&utm_medium=header&utm_campaign=gota365&utm_id=gota365&utm_term=homeclarin",
}) => {
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.innerWidth > 1024) {
        setScroll(window.scrollY > 416);
      } else {
        setScroll(window.scrollY > 36);
      }
    });
  }, []);

  let logoUrl = "/";

  return (
    <Menu
      className={`${!subheaderEnable && "sticky"} ${
        scroll ? "sticky" : "no-sticky"
      }`}
      isHome={isHome}
      id="headerId"
    >
      <ContainerAuto className="containerCenter">
        <ContentLeft>
          <MenuLateral
            menulateral={menulateral}
            suscriptionPrice={suscriptionPrice}
            isHome={isHome}
          />
          {isHome ? (
            <span style={{marginLeft: '5px'}}>
              <a
                href={logoUrl}
                onClick={() =>
                  handleClick("ui_interaction", "header clarin", "inicio")
                }
                aria-label="clarin.com"
              >
                {<ContentIcon fill="#d80026" nameIcon="logoClarin" />}
              </a>
            </span>
          ) : (
            <>
              <Logo
                className="logo"
                href={logoUrl}
                onClick={() =>
                  handleClick("ui_interaction", "header clarin", "inicio")
                }
                aria-label="clarin.com"
              >
                {<ContentIcon fill="#d80026" nameIcon="logoClarin" />}
              </Logo>
              {(!subheaderEnable || scroll) && (
                <SectionScroll
                  href={`${process.env.NEXT_PUBLIC_DOMAIN_URL}/${
                    sectionData.url != undefined ? sectionData.url : ""
                  }`}
                >
                  {sectionData.name}
                </SectionScroll>
              )}
            </>
          )}
        </ContentLeft>

        {!isPreview && (
          <LoginSubscription
            className="contentUser"
            suscriptionPrice={suscriptionPrice}
            urlSubscription={urlSubscription}
            isHome={isHome}
            showGoolgeOneTap={showGoolgeOneTap}
          />
        )}
      </ContainerAuto>
    </Menu>
  );
};

export default HeadingDefault;
