
import { UrgentHeadBandBox,TextUrgent  } from "./styles";
import {ContainerAuto} from "@/shared/ContainerAuto";



export const UrgentModule = ({ urgent }) => {

    const { name, slug, type, extras } = urgent[0];

    return (
        <UrgentHeadBandBox>
            <ContainerAuto>
                <TextUrgent>
                    <h2>
                        {
                            (!!slug && slug.length > 0)
                                ? <a href={slug} target={type}>{name}</a>
                                : name
                        }
                    </h2>
                    {!!extras && extras?.bajada && <span>{extras.bajada}</span>}
                </TextUrgent>
            </ContainerAuto>
        </UrgentHeadBandBox>
    );
}