import styled from "styled-components";
import { Color, Font, breakpoints, ColorDark } from "@/shared/Constants";

export const Topheader = styled.nav `
    ${breakpoints.phone}{
        display: none;
    }
    
    border-top:1px solid #E0E0E0;
    display:flex;
    height: 50px;
    
    ul.nav-bar {
        display:flex;
        align-items:center;
        justify-content: space-between;
        .left{
            display:flex;
            align-items:center;
            .sectionName{
                font-size: 24px;
                font-family:${Font.sansBold};
                color: #000;
                display: flex;
                align-items: center;
                padding-top: 5px;
                &:before {
                    content: "";
                    background-color: #000;
                    display: inline-block;
                    height: 29px;
                    width: 1px;
                    display:block;
                    margin:0 10px;
                }  
            }
            svg{
                cursor:pointer;
                margin-right:4px;
            }
        }
    }
   
    .contentMenu{
        overflow-x: hidden;
    }
    ${breakpoints.desktop}{
        .left{
            width:75%;
        }
    }

`

export const MenuTopMobile = styled.ul` 
    display:none;
    flex-direction:row;
    padding: 0 15px;
    margin-bottom: 3px;
    align-items:center;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    gap: 13px;
    height:55px;
    border-top:1px solid ${Color.lineColor};
    display:none;
        ${breakpoints.darkMode} {
            [data-theme='dark'] & {
            border-top: 0.5px solid ${ColorDark.greySeparator};
            margin-bottom: 0;
            }
        }
        ${breakpoints.phone}{
            display:flex;
        }
    /* justify-content: center; */
    /* width */
    ::-webkit-scrollbar {
        width: 0px;
        height: 0;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background:none; 
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: none; 
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: none; 
    }
    
    li{
        display: flex;
        align-items: center;
        &:last-child{
            &:after {
                display:none;
            }
        }
        &:after {
            content:" ";
            width:2px;
            height:2px;
            border-radius:50%;
            background: ${Color.grey};
            display:inline-block;
            margin-left:10px;
            ${breakpoints.darkMode} {
                [data-theme='dark'] & {
                background:${ColorDark.textLight};
                }
            }
        }
        a {
            ${Font.sansRegular};
            color: ${Color.grey};
            display: block;
            font-size: 12px;
            transition: all .6s ease 0s;
            text-transform:uppercase;
            ${breakpoints.darkMode} {
                [data-theme='dark'] & {
                color:${ColorDark.textLight};
                }
            }
        }        
    }
    
`