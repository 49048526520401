import { ShareBoxUl } from "./styles";
import ContentIcon from "@/shared/ContentIcon";
const ShareFooter = (props) => {
  const {} = props;
  return (
    <ShareBoxUl>
        <li>
          <a href="https://www.facebook.com/clarincom/" aria-label="facebook">
            <ContentIcon
              fill="#555"
              width="16.5px"
              height="17.5px"
              nameIcon="facebook"
            />
          </a>
        </li>
        <li>
          <a href="https://www.twitter.com/clarincom/" aria-label="twitter">
            <ContentIcon
              fill="#555"
              width="16.5px"
              height="17.5px"
              nameIcon="twitter"
            />
            </a>
        </li>
        <li>
          <a href="https://www.linkedin.com/company/clarin/?originalSubdomain=ar" aria-label="linkedin">
            <ContentIcon
              fill="#555"
              width="16.5px"
              height="17.5px"
              nameIcon="linkedin-new"
            />
          </a>
        </li>
        <li>
          <a href="https://instagram.com/clarincom?igshid=ZWQyN2ExYTkwZQ==" aria-label="instagram">
            <ContentIcon
              fill="#555"
              width="16.5px"
              height="17.5px"
              nameIcon="instagram"
            />
          </a>
        </li>
        <li>
          <a href="https://www.tiktok.com/@clarincom?_t=8dDYO9is1TC&_r=1" aria-label="tiktok">
            <ContentIcon
              fill="#555"
              width="16.5px"
              height="17.5px"
              nameIcon="tiktok"
            />
          </a>
        </li>
        <li>
          <a href="https://www.youtube.com/@clarincom" aria-label="youtube">
            <ContentIcon
              fill="#555"
              width="16.5px"
              height="17.5px" 
              nameIcon="youtube"
            />
          </a>
        </li>
    </ShareBoxUl>
  );
};

export default ShareFooter;
