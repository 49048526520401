import { ContetBanner, DummyBanner } from "../style";
import { useState, useEffect } from "react";

const Banner = (props) => {
  const { isPreview, slotId } = props;
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  if (isPreview) {
    return (
      <DummyBanner>
        <p className={`banner-reserved-space`}>Banner {slotId}</p>
      </DummyBanner>
    );
  }

  return isClient ? (
    <ContetBanner className="bannerSRA" id={`div-gpt-ad-${slotId}`} />
  ) : (
    <></>
  );
};

export default Banner;
