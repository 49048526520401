import { UserContent, Avatar, MyAccount, UserName, MyProfile, SignOff, Pic, UserData } from "./style";
import { useAuth0 } from "@auth0/auth0-react";
import { SubscribeButton } from "@/shared/SubscribeButton";
import { deleteCookie } from "helpers";
import { handleClick } from "@/shared/Analitics/GoogleAnalytics4";

export const UserAvatar = ({ suscriptionPrice = "", userAuth0, userSucription }) => {
  
  const { logout } = useAuth0();

  const loadScript = (src) => {
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.onload = resolve;
      script.onerror = reject;
      script.src = src;
      document.head.append(script);
    });
  };

  const loadComments = async () => {
    loadScript("//cdn.viafoura.net/vf-v2.js")
      .then(() => {
        console.log("CARGO VIAFOURA");
        window.vf.session.logout();
        window.vf.context.reset();
      })
      .catch(() => console.error("ERROR EJECUTANDO VIAFOURA."));
  };

  const handlerLogout = async () => {
    handleClick('ui_interaction', 'header clarin', 'Cerrar Sesión');
    await loadComments();
    window.localStorage.removeItem("wads_userProfile");
    deleteCookie("auth0_redir");
    deleteCookie("__token_auth0");
    deleteCookie("statusSus");
    deleteCookie("suscRT");
    deleteCookie("susTemp");
    deleteCookie("isAuthenticated");
    deleteCookie("invalidAuth0");
    logout({ logoutParams: { returnTo: process.env.NEXT_PUBLIC_DOMAIN_URL } });
  };

  return (
    <>
      <UserContent>
        {!userSucription && (
          <SubscribeButton
            title="Suscribite"
            suscriptionPrice={suscriptionPrice}
          />
        )}
        <details>
          <summary>
            <span>Hola, {userAuth0.name}</span>
          <Avatar>
            <img
              height="35"
              width="35"
              src={userAuth0.picture}
              className="avatar"
              alt={userAuth0.name}
            />
          </Avatar>
          </summary>
          <MyAccount>
            <Pic />
            <li>{userAuth0.email}</li>
            <li>
              <MyProfile href="https://miperfil.clarin.com" target="_blank">
                MI PERFIL
              </MyProfile>
            </li>
            <li>
              <SignOff
                onClick={handlerLogout}
                aria-label="Cerrar Sesión"
                href="#"
              >
                CERRAR SESIÓN
              </SignOff>
            </li>
          </MyAccount>
        </details>

      </UserContent>
    </>
  );
};
