import styled from "styled-components";
import { Color, Font, breakpoints } from "@/shared/Constants";

export const UrgentHeadBandBox=styled.div `
    background: ${Color.mainColor};
    height: 73px;
    display: flex;
    align-items: center;
    ${breakpoints.phone}{
        height: auto;
        padding:13px 0;
    }
   
`

export const TextUrgent=styled.div `
h2{
    font-size: 24px;
    line-height:28px;
    color: #fff;
    ${Font.sansBold};
    a{
        color: #fff;
    }
    ${breakpoints.phone}{
        font-size: 20px;
        line-height:25px;
    }
}
span{
    ${Font.sansSemiBold};
    font-size: 11px;
    color:  #fff;
    
}
`