
import React, { useEffect, useState  } from "react";
import { GridNewsToday, ContentNewsToday, DateToday, RightContent, Title } from "./styles";
import {ContainerAuto} from "@/shared/ContainerAuto";
import ContentIcon from "@/shared/ContentIcon";
import { handleClick } from "@/shared/Analitics/GoogleAnalytics4";
import { touchScroll } from "../../swipe";
const NewsToday = ({ noticiashoy }) => {
    const [date, setDate] = useState("");
    useEffect(() => {
        touchScroll('.newsHead');
        setDate(getFormattedDate());
    }, []);

    const getFormattedDate = () => {
        const d = new Date();
        const month = d.toLocaleString('es-AR', { month: 'long' });
        let day = d.toLocaleString('es-AR', { weekday: 'long' });
        day = day.charAt(0).toUpperCase() + day.slice(1);
        return `${day}, ${d.getDate()} de ${month} de ${d.getFullYear()}`;
    };
    
    return (
        <GridNewsToday>
            <ContainerAuto>
                <Title>Noticias hoy</Title>
                <ContentNewsToday className="newsHead">
                    {noticiashoy?.length > 0 && noticiashoy.map((data) => {
                        return (
                            <li key={data._id}>
                                <p className="titleToday">
                                    <a href={data.slug} target="_self" onClick={() => handleClick("ui_interaction", "header noticias de hoy", data.name)}>{data.name}</a>
                                </p>
                            </li>
                        );
                    })
                    }
                </ContentNewsToday>
                <RightContent>
                    <DateToday><span>{date}</span></DateToday>
                </RightContent>
            </ContainerAuto>
        </GridNewsToday>
    );
};

export default NewsToday;