import { useEffect, useRef, useState } from "react";
import { DialogContent, DialogContentOverlay } from "./style";
import { useAuth0 } from "@auth0/auth0-react";
import { deleteCookie, getExpireDate, isEmailMasked, maskEmail } from "helpers";
import { handleClick } from "@/shared/Analitics/GoogleAnalytics4";
import { typeLogin } from "./data";
import { isJson } from "helpers";
import ContentIcon from "@/shared/ContentIcon";

export const RememberUser = ({ userSucription, userAuth0 }) => {
  console.log(userSucription);
  const dialogRef = useRef(null);
  const dialogOverlayRef = useRef(null);
  const contentRef = useRef(null);
  const [userDataSuscription, setUserDataSuscription] = useState();
  const { loginWithRedirect, logout } = useAuth0();

  const loadScript = (src) => {
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.onload = resolve;
      script.onerror = reject;
      script.src = src;
      document.head.append(script);
    });
  };

  const loadComments = async () => {
    await loadScript("//cdn.viafoura.net/vf-v2.js")
      .then(() => {
        console.log("CARGO VIAFOURA");
        window.vf.session.logout();
        window.vf.context.reset();
      })
      .catch(() => console.error("ERROR EJECUTANDO VIAFOURA."));
  };

  const handlerLogout = async () => {
    handleClick(
      "ui_interaction",
      "modal recordatorio",
      "Cerrar Sesión desde modal recordatorio"
    );
    await loadComments();
    window.localStorage.removeItem("wads_userProfile");
    deleteCookie("auth0_redir");
    deleteCookie("__token_auth0");
    deleteCookie("statusSus");
    deleteCookie("suscRT");
    deleteCookie("susTemp");
    logout({
      logoutParams: {
        returnTo:
          process.env.NEXT_PUBLIC_DOMAIN_URL +
          "/ingresar?apw-origin=" +
          window.location.href,
      },
    });
  };

  const redirectLogin = async () => {
    handleClick(
      "ui_interaction",
      "modal recordatorio",
      "Boton Ingresar desde modal recordatorio"
    );
    let expDate = getExpireDate(1);
    document.cookie = `auth0_redir=${
      window.location.href
    };expires=${expDate.toUTCString()};path=/`;
    if (userAuth0) {
      handlerLogout();
    } else {
      loginWithRedirect();
    }
  };

  const ignoreModal = () => {
    handleClick(
      "ui_interaction",
      "modal recordatorio",
      "Botón no soy este usuario"
    );
    localStorage.removeItem("email_suscription");
    closeModal();
  };

  const openModal = () => {
    dialogRef.current.style.display = "block";
    dialogOverlayRef.current.style.display = "flex"
    window.document.documentElement.style.overflow = "hidden"
  };

  const closeModal = () => {
    dialogRef.current.style.display = "none";
    dialogOverlayRef.current.style.display = "none"
    window.document.documentElement.style.overflow = "auto"
  };

  const handleClickOutside = (event) => {
    if (dialogRef.current && !dialogRef.current.contains(event.target)) {
      handleClick(
        "ui_interaction",
        "modal recordatorio",
        "Cerrar al hacer click por fuera del modal recordatorio"
      );
      closeModal();
    }
  };

  const formatMaskEmail = (email) => {
    if (isEmailMasked(email)) {
      return email;
    }
    return maskEmail(email);
  };

  useEffect(() => {
    let emailSuscription = JSON.parse(
      isJson(localStorage.getItem("email_suscription"))
        ? localStorage.getItem("email_suscription")
        : "{}"
    );
    if (!userSucription && emailSuscription?.email) {
      setUserDataSuscription(emailSuscription);
    }
  }, []);

  useEffect(() => {
    if (userDataSuscription?.email) {
      openModal();
    }
  }, [userDataSuscription]);

  
  return (
    <DialogContentOverlay ref={dialogOverlayRef} onClick={handleClickOutside}>
      <DialogContent ref={dialogRef}>
        <button
          className="btn-close"
          onClick={() => {
            handleClick(
              "ui_interaction",
              "modal recordatorio",
              "Botón cerrar modal recordatorio"
            );
            closeModal();
          }}
        >
          <ContentIcon width="18px" height="18px" nameIcon="closeIco" />
        </button>
        <div className="popup-content-container">
          <div className="popup-content-title">
            <div className="icon-paywall">
              <ContentIcon width="13px" height="13px" nameIcon="star" />
            </div>
            <p className="popup-content-text bold">Hola, bienvenido a Clarín</p>
          </div>

          <p className="popup-content-text remember">
            Te recordamos que tenés suscripción digital con el email:
          </p>
          <p className="popup-content-text bold">
            {formatMaskEmail(userDataSuscription?.email)}
          </p>
          {typeLogin[userDataSuscription?.type] ? (
            <p className="popup-content-text">
              Ingresá utilizando tu cuenta de{" "}
              {typeLogin[userDataSuscription?.type]} y leé todo el contenido de
              forma ilimitada.
            </p>
          ) : (
            <p className="popup-content-text">
              Ingresá utilizando tu email y contraseña que generaste en el sitio
              y leé todo el contenido de forma ilimitada.
            </p>
          )}
        </div>
        <div className="popup-buttons">
          <button className="popup-button-login" onClick={redirectLogin}>
            Ingresar
            <ContentIcon
              width="11px"
              height="11px"
              fill="#000"
              nameIcon="avatar"
            />
          </button>
          <button className="popup-buttons-ignore" onClick={ignoreModal}>
            No soy este usuario
          </button>
        </div>
      </DialogContent>
    </DialogContentOverlay>
  );
};
