import { getExpireDate } from "helpers";
import { useAuth0 } from "@auth0/auth0-react";
import { ContainerButtons, Login} from "./style";
import { SubscribeButton } from "@/shared/SubscribeButton";
import ContentIcon from "@/shared/ContentIcon";
import { handleClick } from "@/shared/Analitics/GoogleAnalytics4";
export const ButtonLogin = ({ title = "Suscribite" , suscriptionPrice = "" }) => {
  const { loginWithRedirect } = useAuth0();

  const redirectLogin = async () => {
    handleClick('ui_interaction', 'header clarin', 'Boton Ingresar');
    let expDate = getExpireDate(1);
    document.cookie = `auth0_redir=${
      window.location.href
    };expires=${expDate.toUTCString()};path=/`;
    loginWithRedirect();


  };

  return (
    <ContainerButtons>
      <SubscribeButton 
      title={title} suscriptionPrice={suscriptionPrice}
      />
      <Login
        id="btn-login"
        className="button-login"
        aria-label="Boton Ingresar"
        onClick={redirectLogin}
      >
        <span>Ingresar</span>
        <ContentIcon width= "15px" height="15px" nameIcon='avatar'/>
      </Login>
    </ContainerButtons>
  );
};
