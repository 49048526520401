/* ui_interaction, user_interaction */
export const handleClick = (eventName, section, name, plan = "") => {
  window.dataLayer = window.dataLayer || [];
  const eventData = {
    event: "ga4.trackEvent",
    eventName: eventName,
    eventParams: {
      section: section,
      action: "click",
      element: name,
    },
  };

  if (plan) {
    eventData.eventParams.plan = plan;
  }

  window.dataLayer.push(eventData);
};

/* ui_interaction para Popups */
export const handleClickP = (eventName, section, name, subsection) => {
  window.dataLayer = window.dataLayer || [];
  const eventData = {
    event: "ga4.trackEvent",
    eventName: eventName,
    eventParams: {
      section: section,
      subsection: subsection,
      action: "click",
      element: name,
    },
  };

  window.dataLayer.push(eventData);
};

  /* ui_interaction for AudioPlaylists */
  export const handleClickAPList = (/* eventName, section,  name,*/ subsection, audio_action, audio_title) => {
    window.dataLayer = window.dataLayer || [];
    const eventData = {
      event: "ga4.trackEvent",
      eventName: "audio_interaction",//eventName,
      eventParams: {
        section: "playlist",//section,
        subsection: subsection, //fecha de la playlist
        action: "click",
        //element: name,
        audio_action: audio_action,//{ start | pause | complete | 25% | 50% | 75%, next, previous, close},
        audio_title: audio_title//{titulo del audio}
      },
    };
  
    window.dataLayer.push(eventData);
  };


/* select_content */
export const clickContent = (
  { contentType, contentCategory, contentList },
  url,
  routerAsPath = ""
) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "ga4.trackEvent",
    eventName: "select_content",
    eventParams: {
      content_type: contentType,
      content_category:
        contentCategory ??
        (routerAsPath === "/"
          ? url?.split("/")[3]
          : routerAsPath?.split("/")[1]),

      content_list:
        contentList ??
        (routerAsPath === "/" ? "inicio" : routerAsPath?.split("/")[1]),
    },
  });
};

/* evento para Descarga diploma Claringrilla */
export const clickDownload = (eventName, section, file_name) => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: 'ga4.trackEvent',
    eventName: 'Descargar_Diplo',
    eventParams: {
      file_extension: 'pdf',
      file_name: file_name,
      link_text:'Descargar',
      //link_url: 'https://clarin.com/claringrilla'
      
    },
  })
}

/* ui_interaction, user_interaction */
export const formInteraction = (section, form_name, form_action,form_field) => {
  window.dataLayer = window.dataLayer || [];
  const eventData = {
    event: "ga4.trackEvent",
    eventName: "form_interaction",
    eventParams: {
      section: section,
      form_name: form_name,
      form_action: form_action,
      form_field: form_field
    },
  };
  window.dataLayer.push(eventData);
};