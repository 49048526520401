import { useState, useEffect } from "react";
import dynamic from "next/dynamic";

const Type = {
  TapaPage: dynamic(() => import("./Page")),
  TapaFooter: dynamic(() => import("./Footer")),
};

const Tapa = ({ type }) => {  
  const [clippingsTapa, setClippingsTapa] = useState();
  const [date, setDate] = useState(new Date(new Date().toLocaleString("en-US", { timeZone: "America/Argentina/Buenos_Aires" })));
  const [disabled, setDisabled] = useState(true);
  const [isAdd, setIsAdd] = useState(false);
  const [countError, setCountError] = useState(0);
  const TargetComponent = Type[type];
  const url = "/api/contentsCover";
  
  useEffect(() => {
    const fecha = `${date.getFullYear()}${String(date.getMonth() + 1).padStart(2, "0")}${String(date.getDate()).padStart(2, "0")}`;
    const currentDate = new Date(new Date().toLocaleString("en-US", { timeZone: "America/Argentina/Buenos_Aires" }));
    const currentFecha = `${currentDate.getFullYear()}${String(currentDate.getMonth() + 1).padStart(2, "0")}${String(currentDate.getDate()).padStart(2, "0")}`;
    if (fecha == currentFecha) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }   
    getTapa(fecha);   
    return () => {
      setClippingsTapa("");
    };
  }, [date]);

  const addDay = () => {
    if (disabled) {
      setIsAdd(true);
      setDate((date) => new Date(date.setDate(date.getDate() + 1)));
    }
  };

  const subtractDay = () => {
    setDate((date) => new Date(date.setDate(date.getDate() - 1)));
  };

  const getTapa = async (fecha, param = false) => {
    const response = await fetch(`${url}${param ? "?fecha=" + fecha : "/" + fecha}`);

    const { data } = await response.json();

    validate(data);
  };

  const validate = async (data) => {
    if (data) {
      //if data _id es tapa1
      const tapa = data.filter((e) => e._id === "tapa1");
      setClippingsTapa(tapa[0]);
    } else {
      setCountError(countError + 1);
      if (countError <= 3) {
        if (isAdd) {
          setIsAdd(false);
          setDate((date) => new Date(date.setDate(date.getDate() + 1)));
        } else {
          setDate((date) => new Date(date.setDate(date.getDate() - 1)));
        }
      }
    }
  };

  return (
    <TargetComponent
      next={addDay}
      prev={subtractDay}
      dateView={date}
      tapa={clippingsTapa}
      setDate={setDate}
    />
  );
};

export default Tapa;
