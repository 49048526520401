
import React, { useEffect } from "react";
import { handleClick } from "@/shared/Analitics/GoogleAnalytics4";
import { LiveBlogHeader, LiveDot } from "./styles";
import {ContainerAuto} from "@/shared/ContainerAuto";
import { touchScroll } from "../../swipe";
const Liveblogs = ({ liveblogs = [] }) => {
    useEffect(() => {
        touchScroll('.liveHead');
    }, []);


/* HAY QUE SACAR LAS VARIABLES ALTERNATIVAS, ESTÁN PARA QUE SE VEA HARDCODEADO EN PRODUCCIÓN DE PRUEBA HASTA QUE ESTÉ EN PRODUCCION REAL */
    return (
        <>
          <LiveBlogHeader className="liveBlogStrip">
              <ContainerAuto className="contentLiveblog">
                  <LiveDot>En vivo</LiveDot>
                  <ul className="liveHead">                      
                    {
                        liveblogs?.length > 0 && liveblogs.map((data) => {
                            return (
                                <li key={`live-${data._id}`}>
                                    <h2 className="titleNews">
                                        <a href={data.value?.url ?? data.slug} target="_self" onClick={() => handleClick("ui_interaction","header en vivo",data?.title ?? data.name)}>{data?.title ?? data.name}</a>
                                    </h2>
                                </li>
                            );
                        })
                    }
                  </ul>  
              </ContainerAuto>
          </LiveBlogHeader>
        </>
    )
}

export default Liveblogs;