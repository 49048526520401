import styled from "styled-components";
import { Color, Font, breakpoints, ColorDark } from "@/shared/Constants";

const separator = `
    content: "";
    background-color: ${Color.tertiaryColor};
    display: inline-block;
    height: 20px;
    width: 1px;
    display:block;
    margin:0 18px;
`;

export const LiveBlogHeader = styled.div`
  height: 70px;
  background: ${Color.greyF5F5F5};
  ${breakpoints.darkMode} {
    [data-theme="dark"] & {
      background: ${ColorDark.darkGrey};
    }
  }
  .contentLiveblog {
    display: flex;
  }
  ul {
    height: 70px;
    display: flex;
    align-items: center;
    touch-action: inherit;
    overflow-x: auto;
    overflow-y: hidden;

    li {
      display: flex;
      white-space: nowrap;
      h2.titleNews {
        display: flex;
        align-items: center;
        width: max-content;
        a {
          color: ${Color.grey};
          ${Font.clarinVarSub2Bold};
          font-size: 16px;
          display: flex;
          width: max-content;
          ${breakpoints.darkMode} {
            [data-theme="dark"] & {
              color: ${ColorDark.textLight};
            }
          }
        }
      }
      &:not(:last-child):after {
        ${separator}
      }
    }

    /* width */
    ::-webkit-scrollbar {
      width: 0px;
      height: 0;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: none;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: none;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: none;
    }
  }
`;
export const LiveDot = styled.span`
  font-size: 16px;
  color: ${Color.grey};
  ${Font.sansBold};
  white-space: nowrap;
  display: flex;
  align-items: center;
  ${breakpoints.darkMode} {
    [data-theme="dark"] & {
      color: ${ColorDark.textLight};
    }
  }
  &:before {
    content: "";
    height: 6px;
    width: 6px;
    background-color: ${Color.white};
    border-radius: 50%;
    display: inline-block;
    border: 3px solid ${Color.mainColor};
    margin-right: 8px;
    margin-top: -2px;
    ${breakpoints.darkMode} {
      [data-theme="dark"] & {
        background-color: ${ColorDark.darkGrey};
      }
    }
  }
  &:after {
    ${separator}
    margin:0 14px;
  }
`;
