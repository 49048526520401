import { Color, Font, breakpoints } from "@/shared/Constants";
import styled from "styled-components";

export const UserContent = styled.div`  
  position: relative;
  display: flex;
  ${breakpoints.mobileFirst} {
    details{
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid ${Color.grey};
      border-radius: 50px;
      min-width: 152px;
      height: 44px;
      cursor: pointer;
      margin-left: 15px;
    }
  }
  
  summary{ 
    ::-webkit-details-marker{
      display: none;
    }
    span{
      display: none;
    }
    ${breakpoints.mobileFirst} {
      align-items: center;
      color: ${Color.grey};
      display: flex;
      ${Font.sansBold};
      font-size: 14px;
      white-space: nowrap;
      justify-content: center;
      height: 100%;
      span{
        display: block;
        margin-top: 2px;
        overflow: hidden;
        min-width: 80px;
        height: 100%;
        display: flex;
        align-items: center;
        padding-left: 10px;
      }
    }
  }
`;

export const UserData = styled.details`
  ${breakpoints.mobileFirst} {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #8b8b8b;
    border-radius: 4px;
    padding: 1px 9px;
    
  }
`;

export const Avatar = styled.picture`  
  margin-left: 10px;
  display: flex;
  border: 1px solid ${Color.greySeparator};
  border-radius: 50%;
  padding: 1px;
  img{
    width: 32px;
    height: 32px;
    border-radius: 100%;
  }

`;

export const MyAccount = styled.ul`  
  background-color: ${Color.weatherGray};
  border-radius: 5px;
  padding: 15px;
  position: absolute;
  top: 45px;
  z-index: 2;
  right: 0;
  li{
    color: ${Color.grey};
    cursor: pointer;
    ${Font.sansRegular};
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
    text-align: center;
    width: 100%;
  }
`;

export const UserName = styled.summary`  
  span{
    display: none;
  }
  ${breakpoints.mobileFirst} {
    align-items: center;
    color: ${Color.white};
    display: flex;
    font-family: ${Font.sansRegular};
    font-size: 14px;
    span{
      display: block;
    }
  }
`;

export const MyProfile = styled.a`
  background-color: ${Color.white};
  color: ${Color.grey};
  font-size: 12px;
  font-weight: 700;
  margin-top: 5px;
  padding: 6px;
`;

export const SignOff = styled.a`
  background-color: ${Color.grey888};
  color: ${Color.white};
  font-size: 12px;
  font-weight: 700;
  margin-top: 5px;
  padding: 6px;
`;

export const Pic= styled.span`
  width: 0;
  height: 0;
  position: absolute;
  border-style: solid;
  border-width: 0 8px 8px;
  border-color: transparent transparent ${Color.weatherGray} transparent;
  right: 5px;
  top: -8px;
`;
