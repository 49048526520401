import React, { useState, useEffect } from "react";
import { getEditionNumber } from "@/helpers/getEditionNumber";
import {formatCurrentDateString} from "helpers"
import { RegisterEditionBox } from "./styles";

const RegisterEdition = () => {
  const editionNumber = getEditionNumber();
  const currentDateString = formatCurrentDateString();
  const [isClient, setIsClient] = useState(false)
  useEffect(()=>{
    setIsClient(true)
  },[])
  return (
    isClient ?
    <RegisterEditionBox>
      <div className="middle">
        <div className="register-one">         
          <p className="editor">
            <span>Editor Responsable:</span> Ricardo Kirschbaum{" "}
          </p>
          <p className="regis">
            <span>Registro de Propiedad Intelectual:</span> 4347221{" "}
          </p>
          <p className="edition">
            <span>Edición Nº:</span> {editionNumber}, {currentDateString}
          </p>
          <p className="address">Piedras 1743 / Tacuarí 1846. CP: 1140. C.A.B.A, Argentina</p>
        </div>
        <div className="register-two">
          <p>
            Propietario Arte Gráfico Editorial Argentino Sociedad Anónima © 1996-{new Date().getFullYear()} {" "}
            Clarín.com - Clarín Digital - Todos los derechos reservados.
          </p>
        </div>
        <div className="register-three">
          <a
            aria-label="afip.gob"
            rel="noopener nofollow"
            href="http://qr.afip.gob.ar/?qr=qy9BfPZjufnExnl8h25PyA,,"
            target="_blank"
          >
            <picture>
              <img
                loading="lazy"
                width="40"
                height="55"
                src="https://www.clarin.com/commons/bundles/clarinsite/images/afip.png"
                alt="Afip"
              />
            </picture>
          </a>
          <span>Información fiscal</span>
        </div>
      </div>
    </RegisterEditionBox>
    :
    <></>
  );
};

export default RegisterEdition;