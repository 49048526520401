import { FooterStyle } from "./style";
import RegisterEdition from "./components/RegisterEdition";
import ContentIcon from "@/shared/ContentIcon";
import ShareFooter from "./components/Share";
import LegalFooter from "./components/Legal";
import { ContainerAuto } from "@/shared/ContainerAuto";
import { handleClick } from "@/shared/Analitics/GoogleAnalytics4";
import { menu } from "@/shared/Footer/data";
import { useAppTrue } from "@/hooks/index";
import Tapa from "@/shared/Tapa";

const Footer = () => {

  const isApp = useAppTrue();

  return (
    <>
      {
        (!isApp)
          ? (<ContainerAuto>
            <FooterStyle>
              <div className="separator"></div>
              <div className="icon">
                <ContentIcon
                  fill="#d80026"
                  width="135px"
                  height="35px"
                  display="block"
                  nameIcon="logoClarin"
                />
              </div>
              <div className="top">
                <div className="first-group">
                  <div className="list-container first">
                    <div className="flex-col">
                      <span className="list__title">Suscribite a Clarín</span>
                      <ul className="suscription">
                        {menu?.column1?.map((item, index) => (
                          <li className="point" key={`li${index}`}>
                            <a
                              {...item.attr}
                              onClick={() =>
                                handleClick("ui_interaction", "footer", item.name)
                              }
                            >
                              {item.name}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div className="flex-col">
                      <span className="list__title">Descarga la app de Clarín</span>
                      <ul className="download">
                        {menu?.column2?.map((item, index) => (
                          <li className="point" key={`li${index}`}>
                            <a
                              {...item.attr}
                              onClick={() =>
                                handleClick("ui_interaction", "footer", item.name)
                              }
                            >
                              {item.name}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  <RegisterEdition />
                </div>
                <div className="second-group">


                  <div className="list-container second">

                    <span className="list__title">Secciones</span>
                    <ul className="sections">
                      {menu?.column3?.map((item, index) => (
                        <li className="point" key={`li${index}`}>
                          <a
                            {...item.attr}
                            onClick={() =>
                              handleClick("ui_interaction", "footer", item.name)
                            }
                          >
                            {item.name}
                          </a>
                        </li>
                      ))}
                    </ul>


                    <span className="list__title">Servicios</span>
                    <ul className="services">
                      {menu?.column4?.map((item, index) => (
                        <li className="point" key={`li${index}`}>
                          <a
                            {...item.attr}
                            onClick={() =>
                              handleClick("ui_interaction", "footer", item.name)
                            }
                          >
                            {item.name}
                          </a>
                        </li>
                      ))}
                    </ul>

                  </div>


                  <div className="list-container third">

                    <span className="list__title">Juegos Clarín</span>
                    <ul className="games">
                      {menu?.column5?.map((item, index) => (
                        <li className="point" key={`li${index}`}>
                          <a
                            {...item.attr}
                            onClick={() =>
                              handleClick("ui_interaction", "footer", item.name)
                            }
                          >
                            {item.name == "Más" ? "Más juegos" : item.name}
                          </a>
                        </li>
                      ))}
                    </ul>


                    <span className="list__title">Newsletters</span>
                    <ul className="newsletters">
                      {menu?.column6?.map((item, index) => (
                        <li className="point" key={`li${index}`}>
                          <a
                            {...item.attr}
                            onClick={() =>
                              handleClick("ui_interaction", "footer", item.name)
                            }
                          >
                            {item.name == "Más" ? "Más newsletters" : item.name}
                          </a>
                        </li>
                      ))}
                    </ul>

                  </div>
                </div>
                <div className="third-group">
                  <div className="list-container fourth">

                    <span className="list__title">Grupo Clarín</span>
                    <ul className="clarin-group">
                      {menu?.column7?.map((item, index) => (
                        <li className="point" key={`li${index}`}>
                          <a
                            {...item.attr}
                            onClick={() =>
                              handleClick("ui_interaction", "footer", item.name)
                            }
                          >
                            {item.name}
                          </a>
                        </li>
                      ))}
                    </ul>

                  </div>
                  <div className="list-container fifth">
                    <div className="digital">
                      <span className="list__title">Clarín Digital</span>
                      <ul className="list__1">
                        {menu?.column8?.map((item, index) => (
                          <li className="point" key={`li${index}`}>
                            <a
                              {...item.attr}
                              onClick={() =>
                                handleClick("ui_interaction", "footer", item.name)
                              }
                            >
                              {item.name}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>

                    <span className="list__title"> Publicidad </span>
                    <ul className="list__1">
                      {menu?.column9?.map((item, index) => (
                        <li className="point" key={`li${index}`}>
                          <a
                            {...item.attr}
                            onClick={() =>
                              handleClick("ui_interaction", "footer", item.name)
                            }
                          >
                            {item.name}
                          </a>
                        </li>
                      ))}
                    </ul>


                    <span className="list__title"> Contáctenos </span>
                    <ul className="list__1">
                      {menu?.column10?.map((item, index) => (
                        <li className="point" key={`li${index}`}>
                          <a
                            {...item.attr}
                            onClick={() =>
                              handleClick("ui_interaction", "footer", item.name)
                            }
                          >
                            {item.name}
                          </a>
                        </li>
                      ))}
                    </ul>

                  </div>
                </div>
                <Tapa type="TapaFooter" />
              </div>
              <div className="bottom">
                <ShareFooter />
                <LegalFooter />
              </div>
            </FooterStyle>
          </ContainerAuto>)
          : null
      }
      <noscript>
        <img src="https://sb.scorecardresearch.com/p?c1=2&amp;c2=6906409&amp;cv=4.4.0&amp;cj=1" />
      </noscript>
    </>
  );
};
export default Footer;
