import MenuSection from './components/MenuSection';
import CurrencyToday from "./components/CurrencyToday";
import { Topheader, MenuTopMobile } from "./styles";
import ContentIcon from "@/shared/ContentIcon";
import { ContainerAuto } from "@/shared/ContainerAuto";
import { handleClick } from "@/shared/Analitics/GoogleAnalytics4";

const Menu = ({ menuheader, menuHeaderMobileHome }) => {
    return (
        <>

            <Topheader >
                <ContainerAuto className='contentMenu'>
                    <ul className="nav-bar">
                        <li className="left">
                            <label
                                className="side-menu-label"
                                htmlFor="side-menu-toggle"
                            >
                                <ContentIcon fill="#555" width="21px" height="22px" nameIcon='bars-solid' />
                            </label>
                            <MenuSection menuheader={menuheader} menuHeaderMobileHome={menuHeaderMobileHome} />
                        </li>
                        {/* <li><CurrencyToday /></li> */}
                    </ul>
                </ContainerAuto>
            </Topheader>
            {
                (menuHeaderMobileHome.length) ?
                    <MenuTopMobile>
                        {(
                            menuHeaderMobileHome.map((data) => {
                                return (
                        <li key={data._id} className={data.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[/\s/]/g, "-").toLowerCase()}>
                            <a href={data.slug} target="_self" onClick={() => handleClick("ui_interaction", "menú top mobile", data.name)}>{data.name}</a>
                        </li>
                        );
                            })
                        )}


                    </MenuTopMobile>
                    : null

            }

        </>
    )
}

export default Menu;