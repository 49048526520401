import styled from "styled-components";
import { Font, Color, ColorDark, breakpoints } from "@/shared/Constants";

export const ShareBoxUl = styled.ul `
  width: 100%;
  display: flex;
  gap: 20px;
  margin-top: 20px;
  justify-content: center;
  border-bottom: 0.5px solid ${Color.tertiaryColor};
  border-top: 0.5px solid ${Color.tertiaryColor};
  padding: 20px 0px;
  ${breakpoints.darkMode} {
    [data-theme='dark'] & {
      border-bottom:0.5px solid ${ColorDark.greySeparator};
      border-top:0.5px solid ${ColorDark.greySeparator};
    }
  }
  a {
    cursor: pointer;
    display: flex;
  }
  li {
    width: 35px;
    height: 35px;
    border-radius: 30px;
    border: 1px solid ${Color.grey};
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 4px;
    ${breakpoints.darkMode} {
      [data-theme='dark'] & {
        border:1px solid ${ColorDark.textLight};
      }
    }
    svg{
      padding-left: 0px;
      fill:${ColorDark.textLight};
    }
  }
  p {
    ${Font.clarinVarSub2Regular};
  }
`;