import useGetMoneyPrice from '@/hooks/useGetMoneyPrice';
import { CurrencyTodayBox} from "./styles";
import { handleClick } from '@/shared/Analitics/GoogleAnalytics4';
const CurrencyToday = (props) => {

    const {
        type = 'dolar',
        title = 'Dólar',
    } = props;

    const { officialSale, isLoading } = useGetMoneyPrice(type);
    if (isLoading) {
        return null;
    }
    return (
        <CurrencyTodayBox href={`${process.env.NEXT_PUBLIC_DOMAIN_URL}/economia/divisas-acciones-bonos/`} aria-label="Cotizacion del dólar" onClick={() => handleClick("ui_interaction","header clarin","divisas")}>
            <p className="currency-name">{title}</p>
            <p>{officialSale.localBank} <strong>{officialSale.local} </strong></p>
            <p>{officialSale.aliasMoney} <strong>{officialSale[type]}</strong></p>
        </CurrencyTodayBox>
    );
}

export default CurrencyToday;