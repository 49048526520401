
import styled from "styled-components";
import { Color, Font, breakpoints } from "@/shared/Constants";
export const CurrencyTodayBox= styled.a `
    display:none;
    ${breakpoints.mobileFirst} {
        display: flex;
        .currency-name{
            margin-right:2px;
        }
        p{
            white-space: nowrap;
            ${Font.sansRegular};
            font-size: 14px;
            color: ${Color.grey};
            display: flex;
            align-items: center;
            margin-left: 11px;
            &:nth-child(2){
                margin-left: 5px;
            }
            strong{
                margin-left: 5px;
                ${Font.sansBold};
            }
        }          
    }
`