import { handleClick } from "@/shared/Analitics/GoogleAnalytics4";

const MenuChildren = ({ items = [] }) => {
    return (
        <>
            {
                items.map((item,index) => {
                    return (
                        <span key={`sub-${item._id}`} itemProp="name">
                            <a itemProp="url" key={index} className="linkSub" href={item.slug} target={item.type} onClick={() => handleClick("ui_interaction","sidebar",item.name)}>
                            {item.name}
                            </a>
                        </span>
                       
                    )
                })
            }
        </>
    )
}

export default MenuChildren;
