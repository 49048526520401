import { useAuth0 } from '@auth0/auth0-react';
import jwt_decode from 'jwt-decode';
import { useEffect } from 'react';
import { getExpireDate } from "helpers";

export const GoogleOneTap = () => {

    const { loginWithRedirect } = useAuth0();     
    
    const onOneTapSignedIn = (response) => {  
        let expDate = getExpireDate(1);
        document.cookie = `auth0_redir=${
            window.location.href
        };expires=${expDate.toUTCString()};path=/`; 
        loginWithRedirect({
            authorizationParams: {                                   
                connection: 'google-oauth2',
                login_hint: jwt_decode(response.credential).email,
            }
        });
    }

    const initializeGSI = () => {
        window.google.accounts.id.initialize({
            client_id: process.env.NEXT_PUBLIC_AUTH0_GOOGLE_ID,
            callback: onOneTapSignedIn
        });
        window.google.accounts.id.prompt(notification => {
            if(notification.isNotDisplayed()) {
                console.log(notification.getNotDisplayedReason())
            } else if (notification.isSkippedMoment()) {
                console.log(notification.getSkippedReason())
            } else if (notification.isDismissedMoment()) {
                console.log(notification.getDismissedReason())
            }
        })
    }
    

    const googleOneTap = () => {
        const scriptGsi = document.createElement('script');
        scriptGsi.src = 'https://accounts.google.com/gsi/client';
        scriptGsi.onload = initializeGSI;
        scriptGsi.async = true;
        document.querySelector('body').appendChild(scriptGsi);
    }

    useEffect(() => {
        googleOneTap();
    }, [])
    
  return <></>;
}