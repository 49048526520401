export const getEditionNumber = () => {

    const timeZone = 'America/Argentina/Buenos_Aires';    

    let dateOld = new Date("1996-03-16 04:12:00");
    let dateNow = new Date();

    if( (dateNow.getTimezoneOffset() / -60) !== -3 ){        
        
        dateOld = new Date(new Date().toLocaleString('en-US', { timeZone }));
        dateOld.setHours("04");
        dateOld.setMinutes("12");
        dateOld.setSeconds("00");
        dateOld.setFullYear("1996");
        dateOld.setMonth("02");
        dateOld.setDate("16");

        dateNow = new Date(new Date().toLocaleString('en-US', { timeZone }));
    }

    const unixTimeStampOld = Math.floor(dateOld.getTime() / 1000);

    dateNow.setHours("0");
    dateNow.setMinutes("0");
    dateNow.setSeconds("0");
    const unixTimeStampNow = Math.floor(dateNow.getTime() / 1000);
    const seconds = unixTimeStampNow - unixTimeStampOld;
    let dates = Math.abs(seconds / (60 * 60 * 24));
    dates = Math.floor(dates) - 18;

    return dates;
}