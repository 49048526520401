import { handleClick } from "../Analitics/GoogleAnalytics4";
import { SubscribeBtn } from "./style";
import { useEffect, useState } from "react";
import ContentIcon from "@/shared/ContentIcon";
import { getCookie, setCookie } from "helpers";

export const SubscribeButton = ({
  suscriptionPrice,
  type,
  title,
  titleGA4 = "header clarin",
}) => {

  /* const [isLastDay, setIsLastDay] = useState(false); */

  // por defecto no se muestra el tooltip
  const [classCyberMondayTooltip, setClassCyberMondayTooltip] = useState("hide");

  let price =
    suscriptionPrice && suscriptionPrice !== "" && "por $" + suscriptionPrice;

  const closeTooltip = (event) => {

    event.preventDefault();

    handleClick('ui_interaction', 'ToolTip_cybermonday_buttonSuscribe', 'close_cybermonday_tooltip');

    //se oculta el tooltip
    setClassCyberMondayTooltip("hide");
    //cookie cybermonday_tooltip
    setCookie("cybermonday_tooltip", "hide", 1);
  }

  /* useEffect(() => {
    function checkDay() {
      const today = new Date();
      const isMonday = today.getDay() === 1;
      if (isMonday) {
        setIsLastDay(true);
      }
    }
    checkDay();
  }); */

  useEffect(() => {
    //get cookie cybermonday_tooltip
    //si es distinta de hide, se muestra el tooltip
    getCookie("cybermonday_tooltip") !== "hide" && setClassCyberMondayTooltip("");
  }, []);

  return (
    <SubscribeBtn
      type={type}
      href="/suscripciones/landing.html"
      onClick={() => handleClick("ui_interaction", titleGA4, "Suscribirme")}
    >
      {title}&nbsp;<span className="price">{price}</span>
      {/* <span className="priceBefore">por <strong>$830</strong></span><span className="price">$420</span> */}

      {/* {titleGA4 === "header clarin" ? (
        <div
          //className={`${isLastDay ? "last-day" : null} cybermonday-button`}
          className={`cybermonday-button ${classCyberMondayTooltip}`}
        >

          <figure className="cybermonday-icon">
            <img width="60" height="50" src="https://www.clarin.com/images/collections/Cyber%20Monday/cyber-logo___6723a9d08f01b50018748ef2.png" alt="cyber-monday-icon" />
          </figure>
          <div className="cybermonday-button-text">
            <span>¡Cyber Monday en Clarín!</span>
          </div>

          <button
            className="cybermonday-button-close"
            aria-label="cerrar"
            onClick={closeTooltip}
          >
            <ContentIcon fill="#fff" nameIcon="closeIco" />
          </button>
        </div>
      ) : null} */}
    </SubscribeBtn>
  );
};
