import styled from "styled-components";
import { Font, Color, breakpoints, ColorDark } from "@/shared/Constants";

export const LegalBox = styled.div `
  ul {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 25px;
    justify-content: center;
    padding: 10px 0px 20px;

    ${breakpoints.phone} {
      flex-direction: column;
      gap: 2px;
      align-items: center;
      padding-bottom: 50px;
    }

    li {
      list-style-type: disc;
      text-align:center;
    
    }
    li::marker {
      color: ${Color.grey};
      font-size: 8px;
      ${breakpoints.darkMode} {
        [data-theme='dark'] & {
          color:${ColorDark.textLight};
        }
      }
    }
  }

  a {
    ${Font.sansRegular};
    font-size: 12px;
    line-height: 22px;
    color: ${Color.grey};
    margin-left: 4px;
    ${breakpoints.darkMode} {
      [data-theme='dark'] & {
        color:${ColorDark.textLight};
      }
    }
    span {
      text-decoration: underline;
      margin-bottom: 0.5rem;
    }
  }
`;