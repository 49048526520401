import { Color, Font, breakpoints, ColorDark } from "@/shared/Constants";
import styled from "styled-components";

const Button = `
align-items: center;
    background-color: inherit;
    border: 1px solid ${Color.grey};
    border-radius: 50px;
    display: flex;
    justify-content: center;
    margin: 0 0 0 15px;
    white-space: nowrap;
    cursor: pointer;
    width:34px;
    height:34px;
  ${breakpoints.mobileFirst} {
    width:152px;
    height:44px;
    margin: 0 0 0 15px;
  }
`
export const ContainerButtons = styled.div`
  display: flex;
`;

export const Login = styled.button`  
  ${Button};

  span{
    display:none;
    ${breakpoints.mobileFirst} {
      display: block;
      ${Font.sansRegular};
      font-weight: 700;
      font-size: 14px;
      color: ${Color.grey};
      margin-right:10px;  
      ${breakpoints.darkMode} {
        [data-theme='dark'] & {
          color: ${ColorDark.textLight};
        }
      }
    }
  }
  
  img{
    display: none;
  }
`;

export const AvatarSvg = styled.img`  
  width: 15px;
  height: 21px;
`;
