import { handleClick } from "@/shared/Analitics/GoogleAnalytics4";
import Auth0 from "@/shared/Auth0";

const LoginSubscription = ({
  suscriptionPrice,
  urlSubscription,
  isHome = false,
  showGoolgeOneTap
}) => {
  return (
    <ul className="contentUser">
      <li className="widget-weizenbock">
      <Auth0 suscriptionPrice={suscriptionPrice} isShowGoolgeOneTap={showGoolgeOneTap}/>
      </li>
      {isHome &&(
        <li className="subscription">
          <a
            onClick={() => handleClick("ui_interaction", "header clarin", "Suscripcion 365")}
            className="suscripcion365"
            href={urlSubscription}
            target="_blank"
            rel="noopener"
            aria-label="https://365.clarin.com"
          ></a>
        </li>
      )}
    </ul>
  );
};

export default LoginSubscription;
