
import { handleClick } from "@/shared/Analitics/GoogleAnalytics4";
import { MenuTop } from "./styles";
import ContentIcon from "@/shared/ContentIcon";

const MenuSection = ({ menuheader }) => {
    console.log(menuheader);

    return (

        <MenuTop>
            {
                (menuheader.length)
                    ? (
                        menuheader.map((data) => {
                            const tooltipDirection = data?.extras?.tooltipDirection ? "tooltip-direction-" + data?.extras?.tooltipDirection : "";
                            const itemIcon = data?.extras?.itemIcon;
                            return (
                                <li key={data._id} className={data.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[/\s/]/g, "-").toLowerCase()}>
                                    {itemIcon && <ContentIcon width="30" height="30" nameIcon={itemIcon} />}
                                    <a href={data.slug} target={data.type} onClick={() => handleClick("ui_interaction", "header principal", data.name)}>{data.name}</a>
                                    {(data?.extras?.tooltip == true) && <span className={`tooltip ${tooltipDirection}`}>{data?.extras?.tooltipText}</span>}
                                </li>
                            );
                        })
                    )
                    : null
            }
        </MenuTop>

    )
}

export default MenuSection;
