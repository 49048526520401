import { AppHeaderStyle, HeaderStyle, Section } from "./styles";
import {
  NewsToday,
  Liveblogs,
  Menu,
  HeadingDefault,
  UrgentModule,
} from "./components";
import { useAppTrue } from "@/hooks/index";
import Banner from "@/widgets/Banner/Templates/Banner";
import { ContainerAuto } from "@/shared/ContainerAuto";
import Script from "next/script";
import { useContext } from "react";
import TemplateContext from "@/context/Template/TemplateContext";

const Header = (props) => {
  
  const dataContext = useContext(TemplateContext);
  const { content: formatContent, ...rest } = dataContext || {};
  // si no tengo props, busco contexto
  if (Object.keys(props).length === 0 && !!formatContent) {
    props = {
      ...props,
      ...formatContent?.dataHeader,
    };
  }

  const {
    urgent = [],
    liveblogs,
    menu,
    bannerHorizantalOneEnable = true,
    subheaderEnable = true,
    isNews = false,
    isHome = false,
    showGoolgeOneTap = true,
    newsToday,
    megaWithHtml = false,
  } = props;

  const menulateral = menu?.menulateral?.item?.items ?? [];
  const menuheader = menu?.menuheader?.item?.items ?? [];
  const menuHeaderMobileHome = menu?.menuheadermobile?.item?.items ?? [];

  const sectionData = props.sectionData
    ? props.sectionData
    : { name: "", url: null };

  const isApp = useAppTrue();

  if (isApp)
    return (
      <>
        <AppHeaderStyle>
          <div className="bannerTopHeader">
            <Banner isPreview={props.isPreview} slotId={"horizontal1"} />
          </div>
        </AppHeaderStyle>
        <Script src="/js/appClarin.js" strategy="beforeInteractive" />
      </>
    );

  return (
    <>
      <HeaderStyle>
        {bannerHorizantalOneEnable && (
          <div className="bannerTopHeader">
            <Banner isPreview={props.isPreview} slotId={"horizontal1"} />
          </div>
        )}
        {newsToday && <NewsToday noticiashoy={newsToday} />}
        <HeadingDefault
          isHome={isHome}
          isNews={isNews}
          sectionData={sectionData}
          suscriptionPrice={
            menu?.suscriptionPrice ? menu?.suscriptionPrice : ""
          }
          menulateral={menulateral}
          subheaderEnable={subheaderEnable}
          showGoolgeOneTap={showGoolgeOneTap}
          isPreview={props.isPreview}
        />
        {isHome && (
          <Menu
            menuheader={menuheader}
            sectionData={sectionData}
            menuHeaderMobileHome={menuHeaderMobileHome}
            suscriptionPrice={
              menu?.suscriptionPrice ? menu?.suscriptionPrice : ""
            }
            isPreview={props.isPreview ? props.isPreview : false}
          />
        )}

        {!megaWithHtml && subheaderEnable ? (
          <Liveblogs liveblogs={liveblogs || []} />
        ) : (
          <></>
        )}

        {Array.isArray(urgent) && urgent.length > 0 && (
          <UrgentModule urgent={urgent} />
        )}

        {isNews && subheaderEnable && !megaWithHtml ? (
          <ContainerAuto>
            <Section
              className="SectionSeparator"
              href={`${process.env.NEXT_PUBLIC_DOMAIN_URL}/${
                sectionData.url != undefined ? sectionData.url : ""
              }`}
            >
              {sectionData.name}
            </Section>
          </ContainerAuto>
        ) : (
          <></>
        )}
      </HeaderStyle>
    </>
  );
};

export default Header;
