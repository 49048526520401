import { LegalBox } from "./styles";

const LegalFooter = () => {
  return (
    <LegalBox>
      <ul>
        <li>
          <a className="terms" href="/ayuda/terminos-condiciones.html" rel="nofollow">
            Términos y Condiciones
          </a>
        </li>
        <li>
          <a className="policy" href="/ayuda/politica-proteccion-datos-personales.html" rel="nofollow">
            Política de protección de datos personales
          </a>
        </li>
        <li>
          <a className="rules" href="/ayuda/normas-confidencialidad-privacidad.html" rel="nofollow">
            Normas de confidencialidad y privacidad
          </a>
        </li>
        <li>
          <a className="defense" href="https://www.argentina.gob.ar/produccion/defensadelconsumidor/formulario%20?utm_source=clarin&utm_medium=footer&utm_campaign=clarin&utm_id=defensaconsumidor&utm_term=footerclarin" rel="nofollow" target="_blank">
            Defensa de las y los consumidores: para reclamos ingrese{" "}
            <span>aquí</span>
          </a>
        </li>
      </ul>
    </LegalBox>
  );
};

export default LegalFooter;
